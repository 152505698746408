// synamicLoadManagement.js
const synamicLoadManagement = [
  {
    path: '/circuitsManager/circuits/list',
    name: 'circuitsList',
    component: () => import('@/views/circuitsManager/list/index.vue'),
    meta: { name: 'Virtual Circuit', keepAlive: false, parent: 'Dynamic Load Management' }

  },
  {
    path: '/circuitsManager/circuits/add/:id?',
    name: 'circuitsAdd',
    component: () => import('@/views/circuitsManager/add/index.vue'),
    meta: { name: 'Update', keepAlive: false, breadNumber: 2, parent: 'Dynamic Load Management' }

  },
  {
    path: '/circuitsManager/circuits/detail/:id',
    name: 'circuitsDetail',
    component: () => import('@/views/circuitsManager/detail/index.vue'),
    meta: { name: 'Detail', keepAlive: false, breadNumber: 2, parent: 'Dynamic Load Management' }

  }
]
export default synamicLoadManagement
