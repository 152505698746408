const name = process.env.VUE_APP_NAME
function getDifAddress (name) {
  let apiUrl = null
  switch (name) {
    case 'fooyo':
      apiUrl = 'https://evolta-api.fooyo.sg/'
      break
  }
  return apiUrl
}
const apiUrl = getDifAddress(name)
export default apiUrl
