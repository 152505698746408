import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
// import store from '@/store'
import i18n from '@/i18n/index'
// 路由分模块导入
import storesManager from './modules/storesManager'
import assetsRouteManager from './modules/assetsManager'
import tariffsBillingManager from './modules/tariffsBillingManager'
import CRMManager from './modules/CRMManager'
import synamicLoadManagement from './modules/synamicLoadManagement'
import configurationManager from './modules/configurationManager'
import activitiesManager from './modules/activitiesManager'

Vue.use(VueRouter)

const app_name = process.env.VUE_APP_NAME
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 路由配置规则
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/login/index.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/resetPassword/index.vue')
  },
  {
    path: '/',
    component: Layout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '', // 默认子路由
        name: 'home',
        component: () => import(/* webpackChunkName: 'home' */ '@/views/home/index.vue'),
        meta: { name: 'Dashboard', show: true, index: '/', menu: true }
      },
      ...storesManager,
      ...assetsRouteManager,
      ...tariffsBillingManager,
      ...CRMManager,
      ...synamicLoadManagement,
      ...configurationManager,
      ...activitiesManager,
      {
        path: '/downloadCenter/downloadCenter',
        name: 'downloadCenter',
        component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
        meta: { name: i18n.t('menu.downloadCenter'), show: true, index: '/downloadCenter/downloadCenter', menu: true }
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: '404' */ '@/views/error-page/404.vue')
  }
]

const router = new VueRouter({
  routes
})

// 全局前置守卫：任何页面的访问都要经过这里
// to：要去哪里的路由信息
// from：从哪里来的路由信息
// next：通行的标志
router.beforeEach((to, from, next) => {
  // to.matched 是一个数组（匹配到是路由记录）
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!store.state.user) {
  //     // 跳转到登录页面
  //     next({
  //       name: 'login',
  //       query: { // 通过 url 传递查询字符串参数
  //         redirect: to.fullPath // 把登录成功需要返回的页面告诉登录页面
  //       }
  //     })
  //   } else {
  next() // 允许通过
  // }
  // } else {
  //   next() // 允许通过
  // }

//   // // 路由守卫中一定要调用 next，否则页码无法展示
//   // next()
//   // if (to.path !== '/login') {
//   //   // 校验登录状态
//   // }
})
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
/**
 * @desc 全局监听路由变化
 */
router.beforeEach((to, from, next) => {
  if (app_name === 'zoo' && to.name === 'home') {
    next({
      name: 'ordersManagerticketOrdersList'
    })
  }
  next()
})
export default router
