<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import { getToken } from '@/services/user'
export default {
  data () {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  watch: {
    $route: {
      handler (val) {
        // if (!this.$store.state.app.initIndex) {
        //   if (this.$route.name !== 'login' && this.$route.name !== 'resetPassword') {
        //     getToken().then(data => {
        //       // console.log(data.data)
        //       if (!data.data.admin_user) {
        //         localStorage.removeItem('user')
        //         this.$router.push({
        //           name: 'login'
        //         })
        //       } else {
        //         this.$cookies.set('language', data.data.admin_user.locale)
        //         this.$store.commit('setRole', data.data.admin_user.role)
        //       }
        //     }).catch((e) => {
        //       this.$router.push({
        //         name: 'login'
        //       })
        //     })
        //   }
        //   this.$store.commit('app/INIT_ADD', true)
        // }
      }
    }
  },
  created () {
    this.$cookies.set('language', 'en')
    // console.log('screenWidth', this.screenWidth)
    if (this.screenWidth < 426) {
      this.$store.commit('app/TOGGLE_PHONE', true)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.doResize)
  },
  mounted () {
    window.addEventListener('resize', this.doResize)
    this.$store.commit('app/INIT_ADD', false)
  },
  methods: {
    doResize (event) {
      // setTimeout(() => {
      this.$store.commit('putDocumentWidth', event.srcElement.innerWidth)
      // })
    }
  }
}
</script>

<style lang="scss">
</style>
