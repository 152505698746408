
import i18n from '@/i18n/index'

const storeManager = [
  {
    path: '/storesManager',
    component: () => import('@/views/storesManager/index.vue'),
    meta: { name: i18n.t('menu.storesManager'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 门店
        path: '/storesManager/storesManager',
        component: () => import('@/views/storesManager/storesManager/index.vue'),
        meta: { name: i18n.t('form.store.offlineShop'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'storesManagerList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'storesManagerList',
            component: () => import('@/views/storesManager/storesManager/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'storesManagerAdd',
            component: () => import('@/views/storesManager/storesManager/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'storesManagerEdit',
            component: () => import('@/views/storesManager/storesManager/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'storesManagerDetail',
            component: () => import('@/views/storesManager/storesManager/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default storeManager
