import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en'
import zhLocale from './zh'
import VueCookies from 'vue-cookies'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)
Vue.use(VueCookies)
// 获取当前浏览器语言
const lang = navigator.language || navigator.userLanguage

const i18n = new VueI18n({
  locale: VueCookies.get('language') || lang.substr(0, 2) || 'en', // set locale
  messages: {
    en: {
      ...enLocale,
      ...elementEnLocale
    },
    zh: {
      ...zhLocale,
      ...elementZhLocale
    }
  },
  silentTranslationWarn: true
})
export default i18n
