<template>
	<!-- <div class="appHearBox"> -->
	<div class="header">
		<div class="rightBox">
			<div class="rightSwich leftPoint" @click="backToSilder" v-if="768 < $store.state.documentWidth">
				<svg :class="{'is-active':sidebar.opened}" class="hamburger" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="64" height="64">
					<path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
				</svg>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item v-if="routerList[0] && routerList[0].meta && routerList[0].meta.parent" class="breadcrumb">{{routerList[0].meta.parent}}</el-breadcrumb-item>
				<el-breadcrumb-item v-for="(item,index) in routerList" :key="index" @click.native="gotoPath(item, index)" class="breadcrumb">{{item.meta.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="rightBox">
			<i class="iconfont" :class="fullScreenVisible ? 'icon-defalutScreen' : 'icon-fullScreen'" @click="getFullScreen"></i>
			<!-- <el-dropdown class="rightSwich" @command="handleLanguage">
				<span class="el-dropdown-link">
					{{userInfo && userInfo.language ? userInfo.language === 'zh' ? '中文' : 'English' : language === 'zh' ? '中文' : 'English'}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item :command="'zh'">中文</el-dropdown-item>
					<el-dropdown-item :command="'en'">English</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
			<el-dropdown>
				<span class="el-dropdown-link">
					<el-avatar shape="square" :size="40" :src="(userInfo && userInfo.avatar_url)|| require(`@/assets/${imgurl}/avatar.jpg`)"></el-avatar>
					<!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="handleAdmin">{{ userInfo && userInfo.email }}</el-dropdown-item>
					<el-dropdown-item divided @click.native="handleLogout">{{$t('button.quit')}}</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
	<!-- </div> -->
</template>

<script>
// import { getUserInfo } from '@/services/user'
import { putManagerLang } from '@/services/user'
import screenfull from 'screenfull'
import { mapState } from 'vuex'
// import router from '@/router/index.js'
export default {
  name: 'AppHeader',
  data () {
    return {
      imgurl: process.env.VUE_APP_NAME,
      language:
				this.$cookies.get('language') ||
				navigator.language.split('-')[0] ||
				navigator.userLanguage.split('-')[0] ||
				'en',
      routerList: [],
      // userInfo: {}, // 当前登录用户信息
      fullScreenVisible: false
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar
    }),
    userInfo () {
      return this.$store.state.user
    }
  },
  watch: {
    $route (from, to) {
      this.getBreadcrumb()
    }
  },
  created () {
    // this.userInfo = this.$store.state.user
    const isRefresh = true
    this.getBreadcrumb(isRefresh)
  },
  destroyed () {
    screenfull.on('change', this.getScreenStatus)
  },
  methods: {
    // 前往管理员编辑
    handleAdmin () {
      this.$router.push({
        name: 'adminsEdit',
        params: {
          id: this.userInfo.id
        }
      })
    },
    // 管理菜单栏
    backToSilder () {
      this.$store.dispatch('app/toggleSideBar')
    },
    gotoPath (item, index) {
      if (this.routerList.length === index + 1) return
      this.routerList.splice(index, this.routerList.length - index)
      this.$store.commit('breadListMutations', this.routerList)
      this.$router.push(item.path)
    },
    getFullScreen () {
      screenfull.toggle()
      screenfull.on('change', this.getScreenStatus)
    },
    getScreenStatus () {
      if (screenfull.isFullscreen) {
        // 全屏时，要执行的操作
        this.fullScreenVisible = true
      } else {
        // 取消全屏时，要执行的操作
        this.fullScreenVisible = false
      }
    },
    // 获取面包屑路由
    getBreadcrumb (isRefresh) {
      // const _this = this
      // const routes = router.options.routes
      // const active = []
      // let deep = 0
      // let flag = false
      // function matchRoutes (rou) {
      //   for (let i = 0; i < rou.length; i++) {
      //     if (flag) {
      //       return active
      //     } else {
      //       const item = rou[i]
      //       active[deep] = i
      //       if (item.name === _this.$route.name) {
      //         flag = true
      //         return active
      //       }
      //       if (item.children && item.children.length > 0) {
      //         deep++
      //         matchRoutes(item.children)
      //       }
      //     }
      //   }
      //   if (!flag) {
      //     deep--
      //     active.length = deep
      //   }
      // }
      // const tmpArr = matchRoutes(routes)
      // const arr = []
      // tmpArr.forEach((item, index) => {
      //   if (index === 0) {
      //     const obj = routes[tmpArr[0]]
      //     if (obj.meta && obj.meta.name) {
      //       arr.push(obj)
      //     }
      //   } else {
      //     let obj = routes[tmpArr[0]]
      //     for (let i = 0; i < index; i++) {
      //       obj = obj.children[tmpArr[i + 1]]
      //     }
      //     if (obj.meta && obj.meta.name) {
      //       arr.push(obj)
      //     }
      //   }
      // })
      // this.routerList = arr

      const that = this
      // 由于本项⽬⼤部分属于‘⼀级’页⾯，所以在设置路由时候，⼀级页⾯不设置breadNumber = 1，‘⼆级’页⾯以上才设置breadNumber
      const breadNumber = typeof (this.$route.meta.breadNumber) !== 'undefined' ? this.$route.meta.breadNumber : 1
      // 获取当前页⾯的名字和路由，并组合成新的对象
      const newBread = { name: this.$route.name, path: this.$route.fullPath, meta: this.$route.meta }
      let vuexBreadList = [] // 默认初始化⾯包屑数据
      if (breadNumber !== 1 || isRefresh) {
        // 当前⾯包屑breadNumber⼤于1时才会从vuex中获取值
        vuexBreadList = that.$store.state.breadListState // 获取breadList数组
      }
      // console.log(this.$route)
      // if (breadNumber > 1) {
      if (!isRefresh || vuexBreadList.length === 0) {
        const length = vuexBreadList.length
        vuexBreadList.push(Object.assign(newBread, { index: length }))
      }
      // console.log(vuexBreadList)
      // }
      // if (breadNumber < vuexBreadList.length) {
      //   // "回退"⾯包屑----判断条件：当前路由breadNumber⼩于vuexBreadList的长度
      //   vuexBreadList.splice(breadNumber - vuexBreadList.length, vuexBreadList.length - breadNumber)
      // }
      // if (breadNumber === vuexBreadList.length) {
      //   // "回退"⾯包屑----判断条件：当前路由breadNumber⼩于vuexBreadList的长度
      //   vuexBreadList.splice(1, 1)
      // }
      // if (breadNumber > vuexBreadList.length) {
      //   // 添加⾯包屑----判断条件：当前路由breadNumber⼤于vuexBreadList的长度
      //   vuexBreadList.push(newBread)
      // }
      // 处理完数据后，将最终的数据更新到vuex（⽤于页⾯刷新）
      that.$store.commit('breadListMutations', vuexBreadList)
      // 处理完数据后，将最终的数据更新为新的⾯包屑数组
      that.routerList = vuexBreadList
    },
    async loadUserInfo () {
      // const { data } = await getUserInfo()
      // this.userInfo = data.content
    },
    // 国际化语言修改
    async handleLanguage (val) {
      // console.log(val)
      this.language = val
      // console.log('------------>', this.language)
      const languageMap = { zh: 'zh-CN', en: 'en' }
      await putManagerLang(this.userInfo.id, {
        locale: languageMap[this.language]
      })
      const userInfo = JSON.parse(window.localStorage.getItem('user'))
      userInfo.language = this.language
      this.$store.commit('setUser', userInfo)
      this.$cookies.set('language', this.language)
      // console.log(this.$cookies)
      location.reload()
    },

    handleLogout () {
      this.$confirm(this.$t('message.quitMsg'), this.$t('text.tips'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      })
        .then(() => {
          // 确认执行这里
          // 清除登录状态
          this.$store.commit('setUser', null)

          // 跳转到登录页面
          this.$router.push({
            name: 'login'
          })

          this.$message({
            type: 'success',
            message: this.$t('message.quitSuccess')
          })
        })
        .catch(() => {
          // 取消执行这里
          // this.$message({
          //   type: 'info',
          //   message: '已取消退出'
          // })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.el-dropdown-link {
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.rightBox {
		display: flex;
		align-items: center;
		.iconfont {
			font-size: 20px;
			margin-right: 12px;
		}
		.rightSwich {
			margin-right: 16px;
		}
    .el-breadcrumb span.breadcrumb {
      font-weight: 800 !important;
      cursor: pointer;
    }
    .el-breadcrumb__item {
      padding: 2px 0;
    }
	}
	.leftPoint {
		cursor: pointer;
	}
	.hamburger {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
	}

	.hamburger.is-active {
		transform: rotate(180deg);
	}
}
</style>
