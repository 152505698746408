const assetsRouteManager = [
  {
    path: '/chargingPointManager/chargingPoint/list',
    name: 'chargingPointList',
    component: () => import('@/views/chargingPointManager/list/index.vue'),
    meta: { name: 'Charge Points', keepAlive: true, parent: 'Assets' }
  },
  {
    path: '/chargingPointManager/chargingPoint/add/:id?',
    name: 'chargingPointAdd',
    component: () => import('@/views/chargingPointManager/add/index.vue'),
    meta: { name: 'Update', keepAlive: false, breadNumber: 2, parent: 'Assets' }
  },
  {
    path: '/chargingPointManager/chargingPoint/detail/:id',
    name: 'chargingPointDetail',
    component: () => import('@/views/chargingPointManager/detail/index.vue'),
    meta: { name: 'Charge Points Detail', keepAlive: false, breadNumber: 2, parent: 'Assets' }
  },
  {
    path: '/EVSEsManager/EVSEs/list',
    name: 'EVSEsList',
    component: () => import('@/views/EVSEsManager/list/index.vue'),
    meta: { name: 'EVSEs', keepAlive: false, parent: 'Assets' }

  },
  {
    path: '/EVSEsManager/EVSEs/add/:id?',
    name: 'EVSEsAdd',
    component: () => import('@/views/EVSEsManager/add/index.vue'),
    meta: { name: 'Update', keepAlive: false, breadNumber: 2, parent: 'Assets' }

  },
  {
    path: '/EVSEsManager/EVSEs/detail/:id',
    name: 'EVSEsDetail',
    component: () => import('@/views/EVSEsManager/detail/index.vue'),
    meta: { name: 'EVSEs Detail', keepAlive: false, breadNumber: 2, parent: 'Assets' }
  },
  {
    path: '/locationManager/location/list',
    name: 'locationList',
    component: () => import('@/views/locationManager/list/index.vue'),
    meta: { name: 'Location', keepAlive: false, parent: 'Assets' }

  },
  {
    path: '/locationManager/location/add/:id?',
    name: 'locationAdd',
    component: () => import('@/views/locationManager/add/index.vue'),
    meta: { name: 'Add', keepAlive: false, breadNumber: 2, parent: 'Assets' }
  },
  {
    path: '/locationManager/location/detail/:id',
    name: 'locationDetail',
    component: () => import('@/views/locationManager/detail/index.vue'),
    meta: { name: 'Location Detail', keepAlive: false, breadNumber: 2, parent: 'Assets' }
  }
]
export default assetsRouteManager
