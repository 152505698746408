import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import i18n from '@/i18n/index'
import quillEditor from 'vue-quill-editor'
import * as filters from './filters' // 全局过滤器
import vueNumeralFilterInstaller from 'vue-numeral-filter'

// import 'element-ui/lib/theme-chalk/index.css'
// 加载全局样式
import './styles/index.scss'
// mock数据
// import './mock'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import animated from 'animate.css'
import './icons' // icon
// 全局注册过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' })
Vue.use(quillEditor)
Vue.use(animated)
Vue.use(ElementUI, {
  size: 'small',
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
