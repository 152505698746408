const configurationManager = [
  {
    path: '/adminAccountManager/adminAccount/list',
    name: 'adminAccountList',
    component: () => import('@/views/adminAccountManager/list/index.vue'),
    meta: { name: 'Admin Account', keepAlive: false, parent: 'Configuration' }

  },
  {
    path: '/adminAccountManager/adminAccount/add',
    name: 'adminAccountAdd',
    component: () => import('@/views/adminAccountManager/add/index.vue'),
    meta: { name: 'Update', keepAlive: false, breadNumber: 2, parent: 'Configuration' }

  },
  {
    path: '/adminAccountManager/adminAccount/detail/:id',
    name: 'adminAccountDetail',
    component: () => import('@/views/adminAccountManager/detail/index.vue'),
    meta: { name: 'Admin Account Detail', keepAlive: false, breadNumber: 2, parent: 'Configuration' }

  }
]
export default configurationManager
