const CRMManager = [
  {
    path: '/usersManager/users/list',
    name: 'usersList',
    component: () => import('@/views/usersManager/list/index.vue'),
    meta: { name: 'Users', keepAlive: false, parent: 'CRM' }

  },
  {
    path: '/usersManager/users/add/:id?',
    name: 'usersAdd',
    component: () => import('@/views/usersManager/edit/index.vue'),
    meta: { name: 'Update', keepAlive: false, breadNumber: 2, parent: 'CRM' }

  },
  {
    // 详情
    path: '/usersManager/users/detail/:id',
    name: 'usersDetail',
    component: () => import('@/views/usersManager/detail/index.vue'),
    meta: { name: 'Users Detail', keepAlive: false, breadNumber: 2, parent: 'CRM' }
  }
]
export default CRMManager
