<template>
	<div class="aside">
		<el-menu ref="appaside" @open="handleOpen" @close="handleClose" @select="handleSelect" background-color="#22314D" :unique-opened="true" text-color="#fff" active-text-color="#ffd04b" :collapse="768 > $store.state.documentWidth ? true : !sidebar.opened" router>
			<el-menu-item index="/">
				<i class="el-icon-s-home"></i>
				<span slot="title">Dashboard</span>
			</el-menu-item>
			<el-submenu index="1">
				<template slot="title">
					<i class="el-icon-picture-outline-round"></i>
					<span>Activities</span>
				</template>
				<el-menu-item index="/activities/charging-session/list">
					<svg-icon icon-class="chargingSession" />
					<span slot="title">Charging Sessions</span>
				</el-menu-item>
				<el-menu-item index="/activities/transactions/list">
					<svg-icon icon-class="transactions" />
					<span slot="title">Transactions</span>
				</el-menu-item>
				<el-menu-item index="/faultDiagnosisManager/faultDiagnosis/list">
					<svg-icon icon-class="faultDiagnosis" />
					<span slot="title">Fault Diagnosis</span>
				</el-menu-item>
			</el-submenu>
			<el-submenu index="2">
				<template slot="title">
					<i class="el-icon-s-order"></i>
					<span>Assets</span>
				</template>
				<el-menu-item index="/chargingPointManager/chargingPoint/list">
					<svg-icon icon-class="chargingPoint" />
					<span slot="title">Charge Points</span>
				</el-menu-item>
				<el-menu-item index="/EVSEsManager/EVSEs/list">
					<svg-icon icon-class="EVSEs" />
					<span slot="title">EVSEs</span>
				</el-menu-item>
				<el-menu-item index="/locationManager/location/list">
					<svg-icon icon-class="location" />
					<span slot="title">Location</span>
				</el-menu-item>
			</el-submenu>
			<el-submenu index="3">
				<template slot="title">
					<i class="el-icon-s-ticket"></i>
					<span>Tariffs & Billing</span>
				</template>
				<el-menu-item index="/tariffsManager/tariffs/list">
					<svg-icon icon-class="tariffs" />
					<span slot="title">Tariffs</span>
				</el-menu-item>
				<el-menu-item index="/tariffGroupsManager/tariffGroups/list">
					<svg-icon icon-class="tariffGroups" />
					<span slot="title">Tariff Groups</span>
				</el-menu-item>
			</el-submenu>
			<el-submenu index="4">
				<template slot="title">
					<i class="el-icon-s-custom"></i>
					<span>CRM</span>
				</template>
				<el-menu-item index="/usersManager/users/list">
					<svg-icon icon-class="users" />
					<span slot="title">Users</span>
				</el-menu-item>
			</el-submenu>
			<el-submenu index="5">
				<template slot="title">
					<i class="el-icon-s-management"></i>
					<span>Dynamic Load Management</span>
				</template>
				<el-menu-item index="/circuitsManager/circuits/list">
					<svg-icon icon-class="rise" />
					<span slot="title">Virtual Circuit</span>
				</el-menu-item>
			</el-submenu>
			<el-submenu index="6">
				<template slot="title">
					<i class="el-icon-user"></i>
					<span>Configuration</span>
				</template>
				<el-menu-item index="/adminAccountManager/adminAccount/list">
					<svg-icon icon-class="adminAccount" />
					<span slot="title">Admin Account</span>
				</el-menu-item>
			</el-submenu>
		</el-menu>
	</div>
</template>

<script>
import { mapState } from 'vuex'

// const map = new Map([['13', Vue.$t('menu.productList')], ['14', Vue.$t('menu.orderManagement')]])
export default {
  name: 'AppAside',
  data () {
    return {
      collapse: true,
      map: new Map([
        ['1', this.$t('Activities')],
        ['2', this.$t('Assets')],
        ['3', this.$t('Tariffs & Billing')],
        ['4', this.$t('CRM')],
        ['5', this.$t('Dynamic Load Management')],
        ['6', this.$t('Configuration')]
      ]),
      app_env: process.env.NODE_ENV,
      app_name: process.env.VUE_APP_NAME,
      openeds: ['1', '2', '3', '4', '5', '6']
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar
    }),
    classObj () {
      return {
        hideSidebar: this.sidebar.opened
        // openSidebar: this.sidebar.opened
      }
    }
  },
  watch: {
    $route () {
      this.getheader(true)
    }
  },
  created () {
    // console.log(this.sidebar)
    this.getheader(true)
  },
  methods: {
    // 获取首页
    getheader (flag) {
      const data = this.$route.matched.filter((item) => item.name)
      this.$nextTick(() => {
        if (data[0].name === 'home') {
          this.$refs.appaside.activeIndex = '/'
          this.$refs.appaside.openedMenus = ['/']
        } else {
          if (flag) {
            const routePath = data[0].path.split('/')
            // if (data[0].path === '/promotionManager/referral/add') {
            //   this.$refs.appaside.activeIndex = routePath.join('/')
            // } else {
            const routeArray = [
              routePath[0],
              routePath[1],
              routePath[2],
              'list'
            ]
            const fs = this.$route.path.split('/')[1]
            let index = '/'
            if (['tariffsManager', 'tariffGroupsManager'].includes(fs)) {
              index = '3'
            } else if (['activities', 'faultDiagnosisManager'].includes(fs)) {
              index = '1'
            } else if (['chargingPointManager', 'EVSEsManager', 'locationManager'].includes(fs)) {
              index = '2'
            } else if (['usersManager'].includes(fs)) {
              index = '4'
            } else if (['circuitsManager'].includes(fs)) {
              index = '5'
            } else if (['adminAccountManager'].includes(fs)) {
              index = '6'
            }
            // console.log(index)
            this.$store.commit('putOpenIndex', index)
            if (this.$store.state.documentWidth >= 768) {
              this.$refs.appaside.openedMenus = [this.$store.state.openIndex]
            }
            this.$refs.appaside.activeIndex = routeArray.join('/')
            // }
          }
        }
      })
    },
    handleOpen (key, keyPath) {
      // console.log('1', key, keyPath)
      // console.log(this.$refs.appaside)
    },
    //
    handleClose (key, keyPath) {
      // console.log('2', key, keyPath)
    },
    //
    handleSelect (key, keyPath) {
      this.getMenuName(keyPath[0])
      this.$store.commit('putOpenIndex', keyPath[0])
    },
    getMenuName (index) {
      this.$store.commit(
        'app/TOGGLE_NAME',
        this.map.get(index) === undefined ? '' : this.map.get(index)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.aside {
	height: 100%;
	// 菜单收缩栏样式(关闭时)
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}
	.el-menu:not(.el-menu--collapse) {
		width: 200px;
	}
	.el-menu {
		height: 100%;
	}
	.newSpan {
		transform: translateY(-50%);
		width: 36px;
		height: 16px;
		background: #d74747;
		color: #fff;
		font-size: 12px;
		border-radius: 8px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hideNewSpan {
		top: 50%;
		right: 40px;
	}
	.openNewSpan {
		top: 30%;
		right: 0;
	}
}
</style>
<style lang="scss">
.aside {
	.el-menu {
		.el-tooltip {
			padding-left: 17px !important;
		}
		.el-menu-item i {
			color: #fff;
		}
		.el-menu-item svg{
			font-size: 16px;
		}
		.el-submenu {
			.el-submenu__title {
				display: flex;
				align-items: center;
			}
			.el-submenu__title span {
				white-space: normal;
				word-break: break-word;
				line-height: 20px;
				flex: 1;
				padding-right: 20px;
			}
			.el-submenu__title i {
				color: #fff;
			}
			.el-menu-item {
				display: flex;
				align-items: center;
				background-color: #08152D !important;
				padding-right: 20px !important;
				&:hover {
					background-color: #030C1E !important;
				}
			}
			.el-menu-item span {
				white-space: normal;
				word-break: break-word;
				line-height: 20px;
				flex: 1;
			}
			.el-menu-item {
				padding-left: 25px !important;
			}
		}
		.is-active {
			> div {
				color: rgb(255, 208, 75) !important;
				> i {
					color: rgb(255, 208, 75) !important;
				}
			}
			> i {
				color: rgb(255, 208, 75) !important;
			}
		}
	}
}
</style>
