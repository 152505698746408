// import Cookies from 'js-cookie'
import cookie from 'vue-cookies'

const state = {
  sidebar: {
    opened: cookie.get('sidebarStatus') ? !!+cookie.get('sidebarStatus') : false,
    name: ''
  },
  initIndex: false,
  phone: false,
  loading: null
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    // console.log(cookie)
    state.sidebar.opened = !state.sidebar.opened
    if (state.sidebar.opened) {
      cookie.set('sidebarStatus', 1)
    } else {
      cookie.set('sidebarStatus', 0)
    }
  },
  TOGGLE_NAME: (state, payload) => {
    state.sidebar.name = payload
  },
  TOGGLE_PHONE: (state, payload) => {
    state.phone = payload
  },
  INIT_ADD: (state, payload) => {
    state.initIndex = payload
  }
  // LOADING_MODIFY: (state, payload) => {
  //   if (state.loading) {
  //     state.loading.close()
  //   }
  // }
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
