import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import tagsView from './modules/tagsView'
import dataOperate from './modules/dataOperate'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  // 容器的状态实现了数据共享，在组件里面访问方便，但是没有持久化的功能
  state: {
    user: JSON.parse(window.localStorage.getItem('user') || 'null'),
    url: null,
    permissions: [],
    // user: null // 当前登录用户状态
    role: '',
    openIndex: (window.localStorage.getItem('openIndex') && JSON.parse(window.localStorage.getItem('openIndex'))) || '/',
    documentWidth: document.body.clientWidth,
    breadListState: (window.localStorage.getItem('breadListState') && JSON.parse(window.localStorage.getItem('breadListState'))) || [] // ⾯包屑列表数据
  },

  mutations: {
    // 修改容器数据必须使用 mutation 函数
    setUser (state, payload) {
      state.user = payload

      // 为了防止页面刷新数据丢失，我们需要把 user 数据持久化
      // 注意：本地存储只能存字符串
      window.localStorage.setItem('user', JSON.stringify(payload))
    },
    setPermission (state, payload) {
      state.permissions = payload

      // 为了防止页面刷新数据丢失，我们需要把 user 数据持久化
      // 注意：本地存储只能存字符串
      // window.localStorage.setItem('user', JSON.stringify(payload))
    },
    setUrl (state, payload) {
      state.url = payload
    },
    setRole (state, payload) {
      state.role = payload
    },
    breadListMutations (state, payload) {
      state.breadListState = payload
      window.localStorage.setItem('breadListState', JSON.stringify(payload))
    },
    putDocumentWidth (state, payload) {
      state.documentWidth = payload
    },
    putOpenIndex (state, payload) {
      state.openIndex = payload
      window.localStorage.setItem('openIndex', JSON.stringify(payload))
    }
  },
  actions: {
  },
  getters,
  modules: {
    app,
    tagsView,
    dataOperate
  }
})
