// import Cookies from 'js-cookie'
// import cookie from 'vue-cookies'

const state = {
  operateData: {
    type: undefined,
    id: undefined,
    obj: undefined
  }
}

const mutations = {
  SETDATAID: (state, payload) => {
    state.operateData.id = payload.id
    state.operateData.type = payload.type
    state.operateData.obj = payload.obj
  }
}

const actions = {
  setdataId ({ commit }) {
    commit('SETDATAID')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
