const tariffsBillingManager = [
  {
    path: '/tariffsManager/tariffs/list',
    name: 'tariffsList',
    component: () => import('@/views/tariffsManager/list/index.vue'),
    meta: { name: 'Tariffs', keepAlive: false, parent: 'Tariffs & Billing' }

  },
  {
    path: '/tariffsManager/tariffs/add/:id?',
    name: 'tariffsAdd',
    component: () => import('@/views/tariffsManager/add/index.vue'),
    meta: { name: 'Update', keepAlive: false, breadNumber: 2, parent: 'Tariffs & Billing' }

  },
  {
    path: '/tariffsManager/tariffs/detail/:id',
    name: 'tariffsDetail',
    component: () => import('@/views/tariffsManager/detail/index.vue'),
    meta: { name: 'Tariffs Detail', keepAlive: false, breadNumber: 2, parent: 'Tariffs & Billing' }

  },
  {
    path: '/tariffGroupsManager/tariffGroups/list',
    name: 'tariffGroupsList',
    component: () => import('@/views/tariffGroupsManager/list/index.vue'),
    meta: { name: 'Tariff Groups', keepAlive: false, parent: 'Tariffs & Billing' }

  },
  {
    path: '/tariffGroupsManager/tariffGroups/add/:id?',
    name: 'tariffGroupsAdd',
    component: () => import('@/views/tariffGroupsManager/add/index.vue'),
    meta: { name: 'Update', keepAlive: false, breadNumber: 2, parent: 'Tariffs & Billing' }

  },
  {
    path: '/tariffGroupsManager/tariffGroups/detail/:id',
    name: 'tariffGroupsDetail',
    component: () => import('@/views/tariffGroupsManager/detail/index.vue'),
    meta: { name: 'Tariff Groups Detail', keepAlive: false, breadNumber: 2, parent: 'Tariffs & Billing' }

  }
]
export default tariffsBillingManager
