const activitiesManager = [
  {
    path: '/activities/charging-session/list',
    name: 'chargingSessionList',
    component: () => import('@/views/activities/chargingSession/index.vue'),
    meta: { name: 'Charging Sessions', parent: 'Activities' }
  },
  {
    path: '/activities/charging-session/detail/:id',
    name: 'chargingSessionDetail',
    component: () => import('@/views/activities/chargingSession/detail/index.vue'),
    meta: { name: 'Charging Sessions Detail', keepAlive: false, breadNumber: 2, parent: 'Activities'  }
  },
  {
    path: '/activities/transactions/list',
    name: 'transactionsList',
    component: () => import('@/views/activities/transactions/index.vue'),
    meta: { name: 'Transactions', parent: 'Activities' }
  },
  {
    path: '/faultDiagnosisManager/faultDiagnosis/list',
    name: 'faultDiagnosisList',
    component: () => import('@/views/faultDiagnosisManager/list/index.vue'),
    meta: { name: 'Fault Diagnosis', keepAlive: false, parent: 'Activities'  }
  }
]
export default activitiesManager
